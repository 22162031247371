import { Row, Col } from "antd";

import fallbackImage from "assets/fallbackImage.png";

export default function MatchResult({
  homeName,
  homeImage,
  scoreHome,
  awayName,
  awayImage,
  scoreAway,
}) {
  return (
    <Row justify="space-between" style={{ width: "100%" }} align="middle">
      <Col span={11}>
        <span style={{ marginRight: "6px" }}>{homeName}</span>
        <img
          src={
            homeImage
              ? `${process.env.REACT_APP_MEDIA_FILE}/${homeImage}`
              : fallbackImage
          }
          alt="flag"
          width={30}
          style={{ marginRight: "6px" }}
        />
        <span>{scoreHome}</span>
      </Col>
      <Col span={2}>-</Col>
      <Col span={11}>
        <span style={{ marginRight: "6px" }}>{scoreAway}</span>
        <img
          src={
            awayImage
              ? `${process.env.REACT_APP_MEDIA_FILE}/${awayImage}`
              : fallbackImage
          }
          alt="flag"
          width={30}
          style={{ marginRight: "6px" }}
        />
        <span style={{ marginLeft: "6px" }}>{awayName}</span>
      </Col>
    </Row>
  );
}
