import { Link, useRouteMatch, useLocation } from "react-router-dom";
import { Menu } from "antd";
import HeaderDropdown from "./../HeaderDropdown";
import logo from "assets/wc2022-logo.png";

export default function Header() {
  const { url } = useRouteMatch();
  const { pathname } = useLocation();

  return (
    <div className="content-header">
      <Link to="/">
        <img src={logo} alt="logo" style={{ objectFit: "cover", height: 65 }} />
      </Link>
      <div className="header-right-content">
        <div>
          <Menu
            // theme="light"
            mode="horizontal"
            selectedKeys={[`${pathname}`]}
            style={{
              marginRight: "60px",
            }}
          >
            <Menu.Item key="/dashboard">
              <Link to={`${url}dashboard`}>Dashboard</Link>
            </Menu.Item>
            <Menu.Item key="/match-manager">
              <Link to={`${url}match-manager`}>Quản lý trận đấu</Link>
            </Menu.Item>
            <Menu.Item key="/match-result-manager">
              <Link to={`${url}match-result-manager`}>Quản lý vòng đấu</Link>
            </Menu.Item>
            <Menu.Item key="/player-manager">
              <Link to={`${url}player-manager`}>Quản lý người chơi</Link>
            </Menu.Item>
            <Menu.Item key="/champion-prediction">
              <Link to={`${url}champion-prediction`}>Dự đoán vô địch</Link>
            </Menu.Item>
            <Menu.Item key="/message-manager">
              <Link to={`${url}message-manager`}>Quản lý thông báo</Link>
            </Menu.Item>
            <Menu.Item key="/gift-manager">
              <Link to={`${url}gift-manager`}>Quản lý quà</Link>
            </Menu.Item>
          </Menu>
        </div>
        <HeaderDropdown />
      </div>
    </div>
  );
}
