import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { updateUserInfo } from "Main/mainSlice";
import { login } from "./loginAPI";

export const loginAction = createAsyncThunk(
  "loginSlice/login",
  async (params, thunkAPI) => {
    const res = await login(params);
    thunkAPI.dispatch(updateUserInfo(res.data));
    return res.data;
  }
);

const loginSlice = createSlice({
  name: "loginSlice",
  initialState: {
    loading: false,
    error: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loginAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loginAction.rejected, (state, action) => {
      state.loading = false;
      state.error = "Đăng nhập thất bại";
    });
    builder.addCase(loginAction.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
    });
  },
});

export const selectLogin = (state) => state.login;

export default loginSlice.reducer;
