import axiosClient from "utils";

const fetchStat = async (params) => {
  const url = `/v1/statistic`;
  return axiosClient.get(url, { params });
};

const membersPredictsTop = async (params) => {
  const url = "/v1/statistic/members-predicts-top";
  return axiosClient.get(url, { params });
};

const membersPredictsTopTrue = async (params) => {
  const url = "/v1/statistic/members-predicts-top-true";
  return axiosClient.get(url, { params });
};

const matchesPredictsTop = async (params) => {
  const url = "/v1/statistic/matches-predicts-top";
  return axiosClient.get(url, { params });
};

const matchesPredicts = async (params) => {
  const url = "/v1/statistic/matches-predicts";
  return axiosClient.get(url, { params });
};

export {
  fetchStat,
  membersPredictsTop,
  membersPredictsTopTrue,
  matchesPredictsTop,
  matchesPredicts,
};
