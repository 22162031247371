import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { fetchGift } from "features/MatchDetail/matchDetailAPI";
import { updateGift, addGift } from "./giftAPI";

export const loadGift = createAsyncThunk(
  "giftSlice/fetchGift",
  async (params, thunkAPI) => {
    const res = await fetchGift(params);
    const { total, data } = res;
    return { total, data };
  }
);

export const editGift = createAsyncThunk(
  "giftSlice/updateGift",
  async (params, thunkAPI) => {
    const res = await updateGift(params.id, params.data);
    return res;
  }
);

export const createGift = createAsyncThunk(
  "giftSlice/addGift",
  async (params, thunkAPI) => {
    const res = await addGift(params);
    return res;
  }
);

const giftSlice = createSlice({
  name: "giftSlice",
  initialState: {
    loading: false,
    gift: [],
    total: 0,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadGift.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loadGift.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(loadGift.fulfilled, (state, action) => {
      state.loading = false;
      state.gift = action.payload.data;
      state.total = action.payload.total;
    });
  },
});

export const selectGift = (state) => state.gift;
export default giftSlice.reducer;
