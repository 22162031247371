import axiosClient from "utils";
import { MATCH_RESULT } from "./mock";

async function fetchMatchResult(params) {
  console.log(params);
  return new Promise((resovle, reject) => {
    setTimeout(() => {
      resovle(MATCH_RESULT);
    }, 2000);
  });
}

async function fetchTypeMatchDetail(id) {
  const url = `/v1/rounds/${id}`;
  return axiosClient.get(url);
}

async function updatePoint(id, data) {
  const url = `/v1/rounds/${id}`;
  return axiosClient.put(url, data);
}

async function updateRoundGift(id, data) {
  const url = `/v1/rounds-gifts/${id}`;
  return axiosClient.put(url, data);
}

export { fetchMatchResult, fetchTypeMatchDetail, updatePoint, updateRoundGift };
