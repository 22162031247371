import axios from "axios";
import queryString from "query-string";
import store from "store";
import { message, Tag } from "antd";
import { debounce, isObject, transform, isArray, isEqual } from "lodash";

import {
  statusMatch,
  statusGuess,
  status_noti,
  category_noti,
  type_noti,
  noti_object,
} from "contants";

let errorCatch = true;

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  paramsSerializer: (param) => queryString.stringify(param),
});

axiosClient.interceptors.request.use(async (config) => {
  const _token = JSON.parse(localStorage.auth)?.token || undefined;
  if (_token) {
    config.headers.Authorization = `Bearer ${_token}`;
  }
  return config;
});
axiosClient.interceptors.response.use(
  (response) => {
    if (response && response.data) {
      return response.data;
    }
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      store.remove("auth");
      if (errorCatch) {
        message.warning("Tài khoản hết hiệu lực sử dụng", 4);
      }
      errorCatch = false;
      window.location.href = "/#/login";
    }
    throw error;
  }
);

export default axiosClient;

export const renderStatus = (status, type) => {
  let res = null;
  if (type === "STATUS") {
    res = statusMatch.find((i) => i.status === status);
  }
  if (type === "STATUS_GUESS") {
    res = statusGuess.find((i) => i.status === status);
  }
  if (type === "CATEGORY_NOTI") {
    res = category_noti.find((i) => i.id === status);
  }
  if (type === "STATUS_NOTI") {
    res = status_noti.find((i) => i.status === status);
  }
  if (type === "TYPE_OBJECT_NOTI") {
    res = noti_object.find((i) => i.id === status);
  }
  if (type === "TYPE_NOTI") {
    res = type_noti.find((i) => i.type === status);
    return res ? res.name : "--/--";
  }
  if (res) {
    return <Tag color={res.color}>{res.name}</Tag>;
  }
  return res;
};

export const redirectUrl = (locationPathname, locationQuery) => {
  const redirectUrl = [];
  if (locationPathname) redirectUrl.push(locationPathname);
  if (locationQuery) redirectUrl.push(locationQuery);
  return redirectUrl;
};

export const delayFilter = debounce(
  (values, changedValue, filter, history, url) => {
    const filterType = filter.find(
      (f) => f.name === Object.keys(changedValue)[0]
    ).type;
    if (Object.keys(values).length > 0) {
      for (const i in values) {
        if (!values[i]) {
          delete values[i];
        }
      }
    }
    if (filterType === "input") {
      history.push({
        pathname: url,
        search: queryString.stringify({
          ...values,
          ...changedValue,
          page: 1,
          perPage: 10,
        }),
      });
    } else {
      history.push({
        pathname: url,
        search: queryString.stringify({
          ...values,
          ...changedValue,
          page: 1,
          perPage: 10,
        }),
      });
    }
  },
  1000
);

export const checkStatusEditable = (status) => {
  switch (status) {
    case 1:
    case 4:
    case -4:
      return false;
    case 2:
    case 3:
      return true;

    default:
      return false;
  }
};

export function differentObject(object, base) {
  function changes(object, base) {
    return transform(object, (result, value, key) => {
      if (isArray(value)) {
        if (!isEqual(value, base[key])) {
          result[key] = value;
        }
      } else if (!isEqual(value, base[key])) {
        result[key] =
          !(value instanceof Date) && isObject(value) && isObject(base[key])
            ? changes(value, base[key])
            : value;
      }
    });
  }
  return changes(object, base);
}

export function validURL(str) {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
}

export function unaccent(str) {
  str = str.toLowerCase();
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  // Some system encode vietnamese combining accent as individual utf-8 characters
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // Huyền sắc hỏi ngã nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // Â, Ê, Ă, Ơ, Ư
  return str;
}
